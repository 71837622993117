import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/* import jQuery/$ for plugins */
import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

import { FontAwesomeIcon } from './plugins/font-awesome.js'

import setupInterceptors from './services/setupInterceptors'


setupInterceptors(store)

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app")
