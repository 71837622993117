import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://microservice.innovativetoll.com/',
  headers: {
    'Content-Type': 'application/json'
    
   
  }
})

export default instance



