import api from './api'

class UtilsService {
  /* innovative */
  getStates() {
    return api.get('/states')
  }
  getAgencies() {
    return api.get('/transponder-agencies')
  }
  getOrders() {
    return api.get('/orders')
  }
  updateOrder(id, data) {
    return api.post(`/orders/${id}?_method=put`, data)
  }
  addOrder(data) {
    return api.post('/orders', data)
  }  
  addClients(data) {
    return api.post('/clients', data)
  } 
  getClientAgencies(filter) {
    return api.get('/client-agency', { params: filter})
  }

  getClients() {
    return api.get('/clients')
  }
  getLpTypes() {
    return api.get('/report-types')
  }

  getInventoryStatus() {
    return api.get('/inventory-status')
  }
  getTransponderStatus() {
    return api.get('/transponder-status')
  }

  getColor() {
    return api.get(`/transponder-colors`)
  }
  getTypes() {
    return api.get(`/transpondertypes`)
  } 
  getDepartments(client) {
    return api.get(`/departments?client_id=${client}`)
  }
  getSubDepartments({ dept }) {
    return api.get('/sub-departments', null, { dept })
  }
  getSubsidiaries({ client }) {
    return api.get('/subsidiaries', null, { client })
  }
  getAuthUser() {
    return api.get(`/active-user`)
  }
  CreateUser(data) {
    return api.post('/users', data)
  }
  async getOverviewDept(client) {
    return await api.get(`/default-department/${client}`)
  }
  getclientdetails(clientId) {
    return api.get(`/clients/${clientId}`)
  }
  getDept(deptId) {
    return api.get(`/departments/${deptId}`)
  }
  sendOtp(data) {
    return api.post('/send_email_otp', data)
  } 
  resetPassword(data) {
    return api.post('/updatepassword', data)
  } 

}

export default new UtilsService()
